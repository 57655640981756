<template>
  <v-container fluid pa-0 ma-0>
    <v-toolbar flat color="white">
      <v-toolbar-title> Feedbacks </v-toolbar-title>
      <v-divider class="mx-4" inset vertical></v-divider>
      <!-- <v-spacer></v-spacer> -->
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <a-button
            @click="addFeedback()"
            size="large"
            type="primary"
            v-on="on"
            icon="file-add"
          />
        </template>
        <span>Add Feeback</span>
      </v-tooltip>
    </v-toolbar>
    <v-card
      flat
      style="width: 80%"
      class="mx-auto ma-2 pa-2"
      v-if="cards.length > 0"
    >
      <v-row>
        <v-col v-for="(card, i) in cards" :key="card.title" :cols="6">
          <v-card>
            <v-img
              :src="card.src"
              class="white--text align-end"
              gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.2)"
              height="220px"
            >
            </v-img>
            <v-card-actions>
              <v-card-title v-text="card.title"></v-card-title>
              <v-spacer></v-spacer>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    v-on="on"
                    icon
                    @click="
                      viewImageDialog = true;
                      viewImage = card.src;
                    "
                  >
                    <v-icon>mdi-image</v-icon>
                  </v-btn>
                </template>
                <span>View Image</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    v-on="on"
                    icon
                    v-if="userInfo.name == 'ESTEBAN, JOAN R.'"
                    @click="card.isReply = true"
                  >
                    <v-icon>mdi-reply</v-icon>
                  </v-btn>
                </template>
                <span>Reply to Feedback</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    v-on="on"
                    icon
                    v-if="card.feedbackBy == userInfo.name"
                    @click="editFeedback(card, i)"
                  >
                    <v-icon>mdi-pencil</v-icon>
                  </v-btn>
                </template>
                <span>Edit</span>
              </v-tooltip>
              <!-- <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    v-on="on"
                    icon
                    v-if="card.feedbackBy == userInfo.name"
                    @click="deleteFeedback(i)"
                  >
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </template>
                <span>Delete</span>
              </v-tooltip> -->
            </v-card-actions>
            <v-row dense no-gutters v-if="card.isReply">
              <v-col cols="10">
                <v-text-field
                  class="ma-2"
                  label="Reply"
                  outlined
                  dense
                  v-model="card.reply"
                ></v-text-field>
              </v-col>
              <v-col cols="2">
                <a-button
                  @click="replyFeedback(card, i)"
                  class="mt-3 mr-1"
                  type="primary"
                  icon="export"
                />
                <a-button
                  @click="card.isReply = false"
                  class="mt-3"
                  type="danger"
                  icon="close"
                />
              </v-col>
            </v-row>
            <v-subheader v-else
              >Reply: {{ card.reply ? card.reply : "N/A" }}</v-subheader
            >
            <v-subheader>Feedback from : {{ card.feedbackBy }}</v-subheader>
          </v-card>
        </v-col>
      </v-row>
    </v-card>
    <v-card flat style="width: 80%" class="mx-auto ma-2 pa-2" v-else>
      <a-empty />
    </v-card>

    <!-- ADD DIALOG -->
    <v-dialog v-model="feedbackDialog" max-width="900" persistent>
      <v-card>
        <v-toolbar dense flat color="#1565c0" dark>
          <v-toolbar-title>Feedback</v-toolbar-title>
          <v-spacer />
          <v-icon @click="feedbackDialog = false">mdi-close</v-icon>
        </v-toolbar>
        <v-row no-gutters dense class="mt-3 pa-2">
          <v-col cols="12">
            <v-select
              v-model="item.requestType"
              outlined
              dense
              label="依頼内容"
              :items="OptrequestDetails"
            ></v-select>
          </v-col>
          <v-col cols="12">
            <v-textarea
              label="*依頼内容詳細入力欄"
              outlined
              dense
              v-model="item.requestDetails"
            ></v-textarea>
          </v-col>
          <v-col cols="6">
            <v-file-input
              v-model="item.attachment"
              label="添付ファイル"
              outlined
              dense
            ></v-file-input>
          </v-col>
          <v-col cols="6">
            <v-file-input
              label="Image File"
              outlined
              dense
              accept=".png,.jpg"
              v-model="image"
              @click:clear="isDeleteImage = true"
            ></v-file-input>
          </v-col>
          <v-col cols="6">
            <v-text-field
              v-model="item.consCode"
              label="ルールブック番号"
              outlined
              dense
              class="mr-2"
            ></v-text-field>
          </v-col>
          <v-col cols="6">
            <v-text-field
              v-model="item.docNo"
              label="お客様コード"
              outlined
              dense
            ></v-text-field>
          </v-col>
        </v-row>
        <v-layout class="ma-1 pa-1" dense>
          <v-spacer></v-spacer>
          <v-btn color="#1565c0" dark @click="SaveFeedback()">
            <v-icon class="mr-1">mdi-send</v-icon> 送信
          </v-btn>
        </v-layout>
      </v-card>
    </v-dialog>

    <!-- VIEW IMAGE DIALOG -->
    <v-dialog v-model="viewImageDialog" max-width="900">
      <v-card>
        <v-img :src="viewImage" />
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import S3 from "aws-s3";
import moment from "moment";
import axios from "axios";
import Swal from "sweetalert2";
import AWS from "aws-sdk";
// import config from "../config";
export default {
  data() {
    return {
      item: {},
      image: { name: "" },
      filename: "",
      feedbackDialog: false,
      feedbackDialogTitle: "",
      viewImageDialog: false,
      cards: [],
      viewImage: "",
      isDeleteImage: false,
      isDeleteImgName: "",
      editIndex: -1,
      OptrequestDetails: [
        "ルールブックシステム機能改善提案",
        "ルールブックページの不具合",
        "ルールに関する改善提案",
        "設計業務マニュアルページの不具合",
        "その他",
      ],
    };
  },
  created() {
    this.loadFeebacks();
  },
  methods: {
    loadFeebacks() {
      let url = `${this.api}get/feeback`;
      axios.get(url).then((res) => {
        this.cards = res.data.map((r) => {
          let toInsert = {};
          toInsert.title = r.comment;
          toInsert.isReply = false;
          if (r.reply) {
            toInsert.reply = r.reply;
          } else toInsert.reply = "";
          if (r.imageSrc) {
            if (r.imageSrc.split(".")[1] == "jpg") {
              r.imageSrc = r.imageSrc.replace(".jpg", ".jpeg");
            }
            toInsert.filename = r.imageSrc;
            toInsert.src = `https://s3.us-east-2.amazonaws.com/rulebook.files/Feedback_Images/${r.imageSrc}`;
          }
          toInsert.feedbackBy = r.feedbackBy;
          return toInsert;
        });
      });
    },
    // deleteFeedback(index){
    //   console.log(index)
    //   let url = `${this.api}delete/Feedback?index=${index}`
    //   axios.get(url).then(()=>{
    //     // console.log(res.data)
    //     Swal.fire(
    //       {
    //         icon: "success",
    //         title: "Successfully Deleted!",
    //         showConfirmButton: false,
    //         timer: 1500,
    //       }
    //     )
    //     // location.reload()
    //     setTimeout(() => {
    //       this.loadFeebacks();
    //     }, 1500);
    //   })
    // },
    replyFeedback(data, index) {
      console.log(data.reply);
      console.log(index);
      let toSend = { reply: data.reply, index: index };
      let url = `${this.api}reply/Feedback`;
      axios.post(url, toSend).then((res) => {
        console.log(res.data);
      });
    },
    addFeedback() {
      this.feedbackDialog = true;
      this.feedbackDialogTitle = "Add Feeback";
      this.comment = "";
      this.image = { name: "" };
    },
    editFeedback(data, index) {
      let toEdit = Object.assign({}, data);
      // console.log(index);
      this.editIndex = index;
      if (toEdit.filename) {
        this.isDeleteImgName = toEdit.filename;
        this.image = { name: `${toEdit.filename}` };
      }
      this.feedbackDialogTitle = "Edit Feedback";
      this.comment = toEdit.title;
      this.feedbackDialog = true;
    },
    async SaveFeedback() {
      //TODO SAVE IN DATABASE
      let toInsert = {};
      if (this.feedbackDialogTitle != "Edit Feedback") {
        // if (this.comment) {

        // toInsert._id =
        // toInsert.comment = this.comment;
        // toInsert.feedbackBy = this.userInfo.name;
        // toInsert.feedbackDate = moment().format("YYYY-MM-DD");
        if (this.image.name) {
          this.item.imageSrc = this.image.name;
        }
        console.log(this.item);
        // let url = `${this.api}addFeedback`;
        // axios.post(url, toInsert).then(() => {
        //   if (this.image.name) {
        //     this.uploadImages();
        //   }
        //   // console.log(res.data)
        //   Swal.fire({
        //     icon: "success",
        //     title: "Successfully Added!",
        //     showConfirmButton: false,
        //     timer: 1500,
        //   });
        //   setTimeout(() => {
        //   this.loadFeebacks();
        // }, 1500);
        // });

        // location.reload();

        this.feedbackDialog = false;
        // } else {
        //   Swal.fire({
        //     icon: "error",
        //     title: "Please input comment!",
        //     // text: "Please Generate First !!",
        //   });
        // }
      } else {
        toInsert.comment = this.comment;
        toInsert.feedbackBy = this.userInfo.name;
        toInsert.feedbackDate = moment().format("YYYY-MM-DD");
        if (this.image.name) {
          toInsert.imageSrc = this.image.name;
        }
        toInsert.editIndex = this.editIndex;
        let url = `${this.api}update/Feedback`;
        await axios.post(url, toInsert).then(() => {
          //  console.log(res.data)
          //  console.log(this.isDeleteImage)

          Swal.fire({
            icon: "success",
            title: "Successfully Updated!",
            showConfirmButton: false,
            timer: 1500,
          });
          // if(this.isDeleteImage){
          //   //  this.deleteFileonS3(this.isDeleteImgName)
          //     this.deleteFileonS3()
          //  }
          if (this.image.name) {
            this.uploadImages();
          }
          setTimeout(() => {
            this.loadFeebacks();
          }, 1500);

          this.feedbackDialog = false;
        });
      }
    },
    uploadImages() {
      // console.log(this.image)
      this.filename = this.image.name;
      this.S3Client.uploadFile(this.image, this.filename.split(".")[0]).then(
        () => {
          // console.log("uploaded");
          this.image = { name: "" };
          this.filename = "";
        }
      );
    },
    deleteFileonS3() {
      // console.log(val)
      AWS.config.update({
        accessKeyId: this.accessKeyId,
        secretAccessKey: this.secretAccessKey
      });

      AWS.config.region = "us-east-2";
      var bucketInstance = new AWS.S3();
      var params = {
        Bucket: "rulebook.files",
        Key: `Feedback_Images/${this.isDeleteImgName}`,
      };

      bucketInstance.deleteObject(params, function (err, data) {
        if (data) {
          console.log("File deleted successfully");
        } else {
          console.log("Check if you have sufficient permissions : " + err);
        }
      });
    },
  },
  watch: {
    image() {
      this.isDeleteImage = true;
    },
  },
  computed: {
    config() {
      return {
        bucketName: "rulebook.files",
        dirName: this.directory, //Optional --> directory or folder on s3 where the file will be saved.
        region: "us-east-2", //aws region.
        accessKeyId: this.accessKeyId,
        secretAccessKey: this.secretAccessKey,
        s3Url: this.baseURL, //Optional --> url of your s3 bucket.
      };
    },
    S3Client() {
      return new S3(this.config);
    },
    baseURL() {
      //URL OF YOUR BUCKET
      return "https://s3.us-east-2.amazonaws.com/rulebook.files";
    },
    directory() {
      return "Feedback_Images";
    },
    url() {
      return `${this.baseURL}/${this.directory}/${this.filename}`;
    },
  },
};
</script>